define("client/models/category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.schema = void 0;
  const {
    attr
  } = _emberData.default;
  const schema = {
    name: attr('string'),
    iconUrl: attr('string')
  };
  _exports.schema = schema;

  var _default = _emberData.default.Model.extend(schema);

  _exports.default = _default;
});