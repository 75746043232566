define("client/main/components/forms/user-form/validations-new", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: (0, _validators.validatePresence)({
      presence: true,
      message: "{description} can't be blank"
    }),
    email: [(0, _validators.validatePresence)({
      presence: true,
      message: "{description} can't be blank"
    }), (0, _validators.validateFormat)({
      type: 'email'
    })],
    password: [(0, _validators.validateLength)({
      min: 8
    }), (0, _validators.validatePresence)(true)],
    passwordConfirm: (0, _validators.validateConfirmation)({
      on: 'password',
      message: 'Passwords must match'
    })
  };
  _exports.default = _default;
});