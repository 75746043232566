define("client/router", ["exports", "ember-router-scroll", "client/config/environment"], function (_exports, _emberRouterScroll, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.routerRootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('reset-password');
    this.route('dashboard', {
      path: '/'
    });
    this.route('accounts', function () {
      this.route('edit', {
        path: '/update/:id'
      });
      this.route('new');
    });
    this.route('articles', function () {
      this.route('edit', {
        path: '/update/:id'
      });
      this.route('new');
    });
    this.route('surveys', function () {
      this.route('edit', {
        path: '/update/:id'
      });
      this.route('new');
    });
    this.route('news', function () {
      this.route('edit', {
        path: '/update/:id'
      });
      this.route('new');
    });
    this.route('users', function () {
      this.route('edit', {
        path: '/update/:id'
      });
      this.route('new');
    });
    this.route('user-feedback', function () {});
    this.route('noise-feedback', function () {});
    this.route('news-feedback', function () {});
    this.route('categories', function () {
      this.route('edit', {
        path: '/update/:id'
      });
      this.route('new');
    });
    this.route('configs', {
      path: '/configuration'
    });
    this.route('not-found', {
      path: '/*path'
    });
    this.route('not-found-redirect');
  });
  var _default = Router;
  _exports.default = _default;
});