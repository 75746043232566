define("client/transforms/array", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Transform
  } = _emberData.default;

  var _default = Transform.extend({
    deserialize(serialized) {
      if (Ember.isEmpty(serialized)) {
        return Ember.A();
      }

      return Ember.A(serialized);
    },

    serialize(deserialized) {
      if (Ember.isEmpty(deserialized)) {
        return [];
      }

      return deserialized.map(d => d);
    }

  });

  _exports.default = _default;
});