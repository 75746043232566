define("client/main/user-feedback/index/controller", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    page: 1,
    limit: 10,
    filter: '',
    ajax: Ember.inject.service(),
    apiURL: _environment.default.apiUrl,
    noFilter: Ember.computed('filter', {
      get() {
        return Ember.isBlank(this.get('filter'));
      }

    }),
    paginatedAccounts: Ember.computed('model.users.@each.{name,email}', 'filter', {
      get() {
        return this.get('model.users').map(account => ({
          id: account.get('id'),
          name: account.get('name'),
          email: account.get('email'),
          age: account.get('age'),
          numberOfArticlesOpened: account.get('numberOfArticlesOpened'),
          numberOfArticlesRated: account.get('numberOfArticlesRated'),
          weekendNoiseFeedbackDate: account.get('weekendNoiseFeedbackDate'),
          weekendNoiseFeedbackRate: account.get('weekendNoiseFeedbackRate'),
          weeklyNoiseFeedbackDate: account.get('weeklyNoiseFeedbackDate'),
          weeklyNoiseFeedbackRate: account.get('weeklyNoiseFeedbackRate'),
          zipCode: account.get('zipCode'),
          adultsInHousehold: account.get('adultsInHousehold'),
          bringsAndPickupsPerYear: account.get('bringsAndPickupsPerYear'),
          childrenInHousehold: account.get('childrenInHousehold'),
          flightsPerYear: account.get('flightsPerYear'),
          checked: false
        })).filter(account => {
          const accountHash = `${account.zip}${account.email}`;
          return this.get('noFilter') || accountHash.toUpperCase().includes(this.get('filter').toUpperCase());
        });
      }

    }),
    selectedItems: Ember.computed('paginatedAccounts.@each.checked', {
      get() {
        return this.get('paginatedAccounts').filter(i => i.checked).map(i => this.get('model.users').findBy('id', i.id));
      }

    }),

    checkExistingEmails(email) {
      if (this.get('model.users').find(u => u.get('email') === email)) {
        return this.intl.t('error-messages.user-exists');
      }

      return null;
    },

    actions: {
      validateEmail(email) {
        return this.checkExistingEmails(email);
      },

      selectAll() {
        const selectedCount = this.get('selectedItems.length');
        const allCount = this.get('paginatedAccounts.length');

        if (selectedCount === allCount) {
          this.get('paginatedAccounts').forEach(item => Ember.set(item, 'checked', false));
        } else {
          this.get('paginatedAccounts').forEach(item => Ember.set(item, 'checked', true));
        }
      },

      incrementPage(e) {
        e.preventDefault();
        e.stopPropagation();
      },

      decrementPage() {},

      editAccount(account) {
        this.transitionToRoute('users.edit', account.id);
      },

      async sendInvite(changeset) {
        try {
          if (this.checkExistingEmails(changeset.get('email'))) {
            return;
          }

          changeset.execute();
          const values = Ember.get(changeset, 'data');
          this.set('isProcessing', true);
          values.company = values.company.id;
          await this.get('ajax').post(`${_environment.default.apiUrl}/api/users/invite`, {
            data: values,
            contentType: 'application/json; charset=utf-8'
          });
          this.set('isProcessing', false);
          this.set('isShowingInviteModal', false);
          swal(this.intl.t('confirmation.invited'), this.intl.t('users.invited'), 'success');
        } catch (error) {
          console.error(error);
          this.set('isProcessing', false);
          this.get('notifications').error(this.intl.t('error-messages.default'));
        }
      },

      async deleteSelected(selectedItems) {
        const confirmed = await swal({
          title: this.intl.t('confirmation.question'),
          text: this.intl.t('confirmation.message'),
          dangerMode: true,
          buttons: {
            cancel: {
              text: this.intl.t('actions.cancel'),
              value: null,
              visible: true,
              className: '',
              closeModal: true
            },
            confirm: {
              text: this.intl.t('actions.ok'),
              value: true,
              visible: true,
              className: '',
              closeModal: false
            }
          }
        }).then(async confirmed => {
          if (confirmed) {
            //   await Promise.all(
            //     this.get('model')
            //       .filter(i => selectedItems.map(selected => selected.id).includes(i.id))
            //       .map(i => i.destroyRecord())
            //   );
            try {
              await Promise.all(selectedItems.map(selectedItem => this.get('ajax').delete(`${_environment.default.apiUrl}/api/users/${selectedItem.id}`)));
              this.get('model.users').filter(i => selectedItems.map(selected => selected.id).includes(i.id)).forEach(userRecord => {
                this.get('model.users').removeObject(userRecord);
                userRecord.unloadRecord();
              });
              return true;
            } catch (error) {
              swal(this.intl.t('error-messages.error'), this.intl.t('error-messages.request'), 'error');
            }
          }
        });

        if (confirmed) {
          swal(this.intl.t('confirmation.deleted'), this.intl.t('users.deleted'), 'success');
        }
      },

      doNothing() {}

    }
  });

  _exports.default = _default;
});