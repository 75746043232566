define("client/models/survey", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.schema = void 0;
  const {
    attr
  } = _emberData.default;
  const schema = {
    title: attr('string'),
    event: attr('string'),
    startDate: attr('number'),
    endDate: attr('number'),
    active: attr('boolean'),
    isNotificationSent: attr('boolean'),
    isPushEnabled: attr('boolean'),
    notificationText: attr('string'),
    notificationTime: attr('number')
  };
  _exports.schema = schema;

  var _default = _emberData.default.Model.extend(schema);

  _exports.default = _default;
});