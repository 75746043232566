define("client/main/components/daterange-picker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-group c-daterange-picker'],

    didInsertElement() {
      this.$('input[name="daterange-picker"]').daterangepicker({
        autoUpdateInput: false,
        showYear: true,
        locale: {
          format: 'DD/MM/YY',
          cancelLabel: 'Clear'
        }
      });
      this.$('input[name="daterange-picker"]').on('apply.daterangepicker', (ev, picker) => {
        // $(this).val(picker.startDate.format('DD/MM') + ' - ' + picker.endDate.format('DD/MM'));
        this.onChange(picker.startDate, picker.endDate);
      });
      this.$('input[name="daterange-picker"]').on('cancel.daterangepicker', (ev, picker) => {
        // $(this).val(picker.startDate.format('DD/MM') + ' - ' + picker.endDate.format('DD/MM'));
        this.onChange();
      });
    }

  });

  _exports.default = _default;
});