define("client/main/categories/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    page: 1,
    limit: 10,
    filter: '',
    noFilter: Ember.computed('filter', {
      get() {
        return Ember.isBlank(this.get('filter'));
      }

    }),
    paginatedItems: Ember.computed('model.categories.@each.{name}', 'filter', {
      get() {
        return this.get('model.categories').map(item => ({
          id: item.get('id'),
          name: item.get('name'),
          checked: false
        })).filter(item => {
          const itemHash = `${item.name}`;
          return this.get('noFilter') || itemHash.toUpperCase().includes(this.get('filter').toUpperCase());
        });
      }

    }),
    selectedItems: Ember.computed('paginatedItems.@each.checked', {
      get() {
        return this.get('paginatedItems').filter(i => i.checked).map(i => this.get('model.categories').findBy('id', i.id));
      }

    }),
    actions: {
      selectAll() {
        const selectedCount = this.get('selectedItems.length');
        const allCount = this.get('paginatedItems.length');

        if (selectedCount === allCount) {
          this.get('paginatedItems').forEach(item => Ember.set(item, 'checked', false));
        } else {
          this.get('paginatedItems').forEach(item => Ember.set(item, 'checked', true));
        }
      },

      async deleteSelected(selectedItems) {
        const confirmed = await swal({
          title: this.intl.t('confirmation.question'),
          text: this.intl.t('confirmation.delete-linked'),
          dangerMode: true,
          buttons: {
            cancel: {
              text: this.intl.t('actions.cancel'),
              value: null,
              visible: true,
              className: '',
              closeModal: true
            },
            confirm: {
              text: this.intl.t('actions.ok'),
              value: true,
              visible: true,
              className: '',
              closeModal: false
            }
          }
        }).then(async confirmed => {
          if (confirmed) {
            try {
              await Promise.all(this.get('model').filter(i => selectedItems.map(selected => selected.id).includes(i.id)).map(i => i.destroyRecord()));
              return true;
            } catch (error) {
              swal(this.intl.t('error-messages.error'), this.intl.t('error-messages.request'), 'error');
            }
          }
        });

        if (confirmed) {
          swal(this.intl.t('confirmation.deleted'), this.intl.t('categories-page.deleted'), 'success');
        }
      }

    }
  });

  _exports.default = _default;
});