define("client/main/categories/new/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model() {
      return Ember.RSVP.hash({
        category: this.store.createRecord('category')
      });
    },

    actions: {
      willTransition() {
        if (this.controller.get('model.category')) {
          this.controller.get('model.category').unloadRecord();
        }
      }

    }
  });

  _exports.default = _default;
});