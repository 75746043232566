define("client/main/dashboard/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),

    beforeModel() {
      if (this.get('session.currentUser.type') === 'users-manager') {
        this.transitionTo('users');
      } else {
        this.transitionTo('news');
      }
    }

  });

  _exports.default = _default;
});