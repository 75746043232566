define("client/main/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RI/4Bsub",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"processing-indicator\",null,[[\"show\"],[true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/main/loading/template.hbs"
    }
  });

  _exports.default = _default;
});