define("client/authenticators/torii", ["exports", "ember-simple-auth/authenticators/torii"], function (_exports, _torii) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _torii.default.extend({
    torii: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    firebase: Ember.inject.service(),

    injectCurrentUserInSession(sessionData) {
      const ref = this.get('firebase');
      ref.child('/users').on('value', snapshot => {
        let userExists = false;
        snapshot.forEach(function (user) {
          if (!userExists) {
            userExists = user.key === Ember.get(sessionData, 'uid');
          }
        });

        if (!userExists) {
          this.get('session').invalidate();
        }
      });
      return this.get('store').findRecord('user', Ember.get(sessionData, 'uid')).then(userData => {
        if (!['admin', 'manager', 'editor', 'users-manager'].includes(userData.get('type'))) {
          throw new Error('User is not allowed access the dashboard. Please contact an administrator.');
        }

        this.set('session.currentUser', userData);
      });
    },

    restore() {
      const restored = this._super(...arguments);

      return restored.then(this.injectCurrentUserInSession.bind(this)).then(() => restored);
    },

    authenticate() {
      const authenticated = this._super(...arguments);

      return authenticated.then(this.injectCurrentUserInSession.bind(this)).then(() => authenticated);
    }

  });

  _exports.default = _default;
});