define("client/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "actions": {
      "add": "Add",
      "cancel": "Cancel",
      "delete": "Delete",
      "edit": "Edit",
      "export": "Export",
      "ok": "OK",
      "save": "Save",
      "saveDraft": "Save as Draft",
      "send": "Send",
      "update": "Update"
    },
    "articles-page": {
      "add": "Add Article",
      "categories": "Categories",
      "created": "Article added.",
      "deleted": "Article removed.",
      "form-title": "Article Info",
      "index": "Index",
      "list": "Article List",
      "model": "Article",
      "models": "Articles",
      "saved": "Article saved.",
      "title": "Articles"
    },
    "categories-page": {
      "add": "Add Category",
      "add-article": "Add Category Article",
      "created": "Item added.",
      "deleted": "Item removed.",
      "index": "Environment",
      "model": "Category",
      "parent": "Parent",
      "products": "Product",
      "saved": "Item saved.",
      "subcategories": "Sub-categories",
      "title": "Categories"
    },
    "configs-page": {
      "form-title": "Application settings",
      "saved": "Configuration saved.",
      "title": "Configuration"
    },
    "confirmation": {
      "delete-linked": "This operation will remove selected items and all linked child items.",
      "deleted": "Deleted!",
      "invited": "Invite sent!",
      "message": "This operation can't be undone!",
      "question": "Are you sure?",
      "reset-password-success": "Password reset email sent!"
    },
    "error-messages": {
      "default": "Something went wrong, please try again.",
      "error": "Error!",
      "form": "Please fix the input errors.",
      "image-upload": "There was an error uploading the image. Please try again.",
      "request": "The request failed!",
      "reset-password": "There was an error reseting your password.",
      "reset-password-success": "Password reset email has been sent.",
      "user-exists": "Account with this email already exists."
    },
    "labels": {
      "all-months": "All months",
      "all-years": "All years",
      "disabled": "Disabled",
      "enabled": "Enabled",
      "index": "Index",
      "main": "Eindhoven Airport",
      "months": {
        "short": {
          "apr": "apr",
          "aug": "aug",
          "dec": "dec",
          "feb": "feb",
          "jan": "jan",
          "july": "july",
          "jun": "jun",
          "mar": "mar",
          "may": "may",
          "nov": "nov",
          "oct": "oct",
          "sep": "sep"
        }
      },
      "new": "New",
      "no-items": "There are no items yet.",
      "not-specified": "Not Specified",
      "optional": "Optional"
    },
    "languages": {
      "dutch": "Dutch",
      "english": "English"
    },
    "management-page": {
      "index": "Administrator",
      "title": "User Management"
    },
    "models": {
      "article": {
        "active": "Active",
        "category": "Category",
        "content": "Content",
        "subtitle": "Subtitle",
        "title": "Title"
      },
      "category": {
        "name": "Name"
      },
      "configs": {
        "aboutAirport": "About Eindhoven Airport",
        "aboutBurenApp": "Questions about the BurenApp",
        "accessibility": "Accessibility",
        "mapInfo": "Map Info",
        "openingHours": "Opening hours"
      },
      "news": {
        "active": "Active",
        "categories": {
          "airforce": "Airforce",
          "airlines": "Airlines",
          "brainport_eindhoven": "Brainport Eindhoven",
          "environment": "Environment",
          "other": "Other",
          "partners_and_events": "Partners & events"
        },
        "content": "Content",
        "notificationText": "Push Notification Text",
        "notificationTime": "Push Notification Date",
        "push": "Enable Push Notification",
        "shortDescription": "Short Description",
        "time": "Publish Date",
        "title": "Title",
        "totalFeedback": "Total Feedback",
        "videoLink": "YouTube Video"
      },
      "survey": {
        "active": "Active",
        "end": "End Date",
        "event": "Event Name",
        "start": "Start Date",
        "title": "Survey title"
      },
      "user": {
        "adultsInHousehold": "Adults in Household",
        "age": "Age Group",
        "bringsAndPickupsPerYear": "Airport Transfer",
        "childrenInHousehold": "Children in Household",
        "email": "Email",
        "flightsPerYear": "Flights per Year",
        "forms": {
          "default": "User Info",
          "invite": "Invite User"
        },
        "name": "Name",
        "password": "Password",
        "passwordConfirm": "Confirm Password",
        "type": "Type",
        "zipCode": "ZIP Code"
      }
    },
    "news-feedback-page": {
      "article-filter": "All Articles",
      "average-ratings": "Average Rating",
      "overview": "Overview",
      "rates": "Rates",
      "title": "News",
      "total-ratings": "Total Ratings"
    },
    "news-page": {
      "drafts": "Draft Articles",
      "index": "News",
      "list": "Article List",
      "model": "Article",
      "models": "Articles",
      "title": "Articles"
    },
    "noise-feedback-page": {
      "average-ratings": "Average Rating",
      "by-hour": "Ratings by Hour",
      "daily-feedback": "Daily Noise",
      "daily-filter": "All Dates",
      "export-week": "Export Data",
      "export-weekend": "Export Weekend Data",
      "rates": "Rates",
      "title": "Noise",
      "total-ratings": "Total Ratings",
      "week-feedback": "Week Noise",
      "week-filter": "All Weeks",
      "weekend-feedback": "Weekend Noise",
      "weekend-filter": "All Weekends",
      "zip-code-filter": "All ZIP Codes"
    },
    "placeholders": {
      "article-search": "Find your article (title)...",
      "category-search": "Search for a category...",
      "search": "Type to search...",
      "survey-search": "Find your survey (title)..."
    },
    "sidebar": {
      "articles": "Articles",
      "categories": "Categories",
      "config": "Configuration",
      "environment": "Environment",
      "feedback": "Feedback",
      "news": "News",
      "noise": "Noise",
      "surveys": "Surveys",
      "user-management": "User Management",
      "users": "Users"
    },
    "surveys-page": {
      "add": "Add Survey",
      "created": "Survey added.",
      "deleted": "Survey removed.",
      "form-title": "Survey Info",
      "index": "Index",
      "list": "Survey List",
      "model": "Survey",
      "models": "Surveys",
      "saved": "Survey saved.",
      "title": "Surveys"
    },
    "users-page": {
      "add": "Add User",
      "created": "User created.",
      "deleted": "User has been deleted.",
      "index": "Feedback",
      "invite": "Invite User",
      "invited": "Invite email sent successfully.",
      "model": "User",
      "new": "New User",
      "saved": "User updated.",
      "title": "Users"
    }
  };
  _exports.default = _default;
});