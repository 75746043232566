define("client/main/components/date-picker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-group c-date-picker'],
    isTimepicker: false,

    didInsertElement() {
      this.$('input[name="picker"]').bootstrapMaterialDatePicker({
        format: this.format || 'DD / MMMM YYYY',
        time: this.isTimepicker
      });
    }

  });

  _exports.default = _default;
});