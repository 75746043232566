define("client/main/application/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    router: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.get('notifications').setDefaultAutoClear(true);
      this.get('notifications').setDefaultClearDuration(1200);
    }

  });

  _exports.default = _default;
});