define("client/main/news/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    firebase: Ember.inject.service(),

    model() {
      const ref = this.get('firebase');
      const getNewsFeedback = new Ember.RSVP.Promise((resolve, reject) => {
        try {
          ref.child('/newsFeedback').on('value', snapshot => resolve(snapshot.val()));
        } catch (error) {
          console.error(error);
          reject(error);
        }
      });
      return Ember.RSVP.hash({
        articles: this.store.findAll('new', {
          reload: true
        }),
        feedback: getNewsFeedback
      });
    }

  });

  _exports.default = _default;
});