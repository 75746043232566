define("client/services/ajax", ["exports", "ember-ajax/services/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    headers: Ember.computed('session.data.authenticated', {
      get() {
        const headers = {};
        const authData = this.get('session.data.authenticated');

        if (authData) {
          headers.Authorization = JSON.stringify({
            uid: authData.uid,
            idToken: authData.idToken,
            refreshToken: authData.refreshToken
          });
        }

        return headers;
      }

    }),

    init() {
      this._super(...arguments);

      this.trustedHosts = [/\.example\./, 'localhost'];
    }

  });

  _exports.default = _default;
});