define("client/main/components/date-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8HZBdOT/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"labelText\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\"],[9],[1,[21,\"labelText\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"input\"],[11,\"class\",\"form-control\"],[11,\"name\",\"picker\"],[12,\"placeholder\",[21,\"placeholder\"]],[12,\"onchange\",[21,\"onChange\"]],[12,\"value\",[21,\"value\"]],[11,\"type\",\"text\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/main/components/date-picker/template.hbs"
    }
  });

  _exports.default = _default;
});