define("client/main/components/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QgZ7wa9G",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\"],[12,\"accept\",[21,\"accept\"]],[12,\"data-default-file\",[28,[[21,\"value\"]]]],[11,\"data-max-file-size-preview\",\"50M\"],[11,\"class\",\"dropify\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"upload\"],[[\"value\"],[\"target.files\"]]]],[11,\"type\",\"file\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"isUploading\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"uploading-overlay\"],[9],[0,\"\\n        \"],[1,[27,\"paper-progress-circular\",null,[[\"value\"],[[23,[\"uploadProgress\"]]]]],false],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"uploading-overlay__text\"],[9],[0,\"Uploading...\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/main/components/file-upload/template.hbs"
    }
  });

  _exports.default = _default;
});