define("client/main/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5i62RfsF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"and\",[[23,[\"session\",\"isAuthenticated\"]],[27,\"not-eq\",[[23,[\"router\",\"currentRouteName\"]],\"not-found\"],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"id\",\"main-wrapper\"],[11,\"class\",\"main-wrapper-container\"],[9],[0,\"\\n        \"],[1,[21,\"layout/main-navbar\"],false],[0,\"\\n        \"],[1,[21,\"layout/main-sidebar\"],false],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"page-wrapper\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n                \"],[1,[21,\"outlet\"],false],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"footer\"],[11,\"class\",\"footer\"],[9],[0,\" © 2022 Glamorous Goat \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}],[1,[21,\"ember-load-remover\"],false],[0,\"\\n\"],[1,[27,\"notification-container\",null,[[\"position\"],[\"top\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/main/application/template.hbs"
    }
  });

  _exports.default = _default;
});