define("client/main/articles/edit/controller", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    isProcessing: false,
    linkableCategories: Ember.computed('model.categories.[]', {
      get() {
        return Ember.get(this, 'model.categories').filter(c => !c.get('parent.id') && !c.get('isNew'));
      }

    }),
    actions: {
      goBack() {
        this.transitionToRoute('articles');
      },

      async save(changeset) {
        try {
          this.set('isProcessing', true);
          const sendNotification = false; // changeset.get('change').isPushEnabled || changeset.get('change').isActive;

          const saved = await changeset.save(); // if (sendNotification && saved.get('isActive') && saved.get('isPushEnabled')) {
          //   await this.get('ajax').post(`${ENV.apiUrl}/api/notifications`, {
          //     data: {
          //       topic: 'articles',
          //       notificationText: saved.get('notificationText'),
          //       title: saved.get('title'),
          //       key: saved.get('id')
          //     },
          //     contentType: 'application/json; charset=utf-8'
          //   });
          // }

          this.set('isProcessing', false);
          this.get('notifications').success(this.intl.t('articles-page.saved'));
          this.transitionToRoute('articles');
        } catch (error) {
          console.error(error);
          this.set('isProcessing', false);
          this.get('notifications').error(this.intl.t('error-messages.default'));
        }
      }

    }
  });

  _exports.default = _default;
});