define("client/main/components/forms/survey-form/component", ["exports", "ember-changeset", "ember-changeset-validations", "moment", "client/main/components/forms/survey-form/validations"], function (_exports, _emberChangeset, _emberChangesetValidations, _moment, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'form',
    classNames: [''],
    intl: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),

    async submit(e) {
      e.preventDefault();
      const changeset = this.get('changeset');
      const snapshot = changeset.snapshot();

      try {
        await changeset.validate();

        if (Ember.get(changeset, 'isInvalid')) {
          this.get('notifications').error(this.intl.t('error-messages.form'));
        } else {
          this.onSubmit(changeset);
        }
      } catch (error) {
        console.error(error);
        changeset.restore(snapshot);
        this.get('notifications').error(this.intl.t('error-messages.default'));
      }
    },

    changeset: Ember.computed('model', {
      get() {
        const model = Ember.get(this, 'model');
        const changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_validations.default), _validations.default);
        return changeset;
      }

    }),
    formattedStartDate: Ember.computed('changeset.startDate', {
      get() {
        if (!this.get('changeset.startDate')) {
          return '';
        }

        return (0, _moment.default)(this.get('changeset.startDate')).format('HH:mm DD / MMMM YYYY');
      }

    }),
    formattedEndDate: Ember.computed('changeset.endDate', {
      get() {
        if (!this.get('changeset.endDate')) {
          return '';
        }

        return (0, _moment.default)(this.get('changeset.endDate')).format('HH:mm DD / MMMM YYYY');
      }

    }),
    formattedNotificationTime: Ember.computed('changeset.notificationTime', {
      get() {
        if (!this.get('changeset.notificationTime')) {
          return '';
        }

        return (0, _moment.default)(this.get('changeset.notificationTime')).format('HH:mm DD / MMMM YYYY');
      }

    }),
    actions: {
      updateStartDate(inputEvent, date) {
        this.get('changeset').set('startDate', date.valueOf());
      },

      updateEndDate(inputEvent, date) {
        this.get('changeset').set('endDate', date.valueOf());
      },

      updateNotificationDate(inputEvent, date) {
        this.get('changeset').set('notificationTime', date.valueOf());
      }

    }
  });

  _exports.default = _default;
});