define("client/models/new", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.schema = void 0;
  const {
    attr
  } = _emberData.default;
  const schema = {
    imageUrl: attr('string'),
    images: attr('array'),
    title: attr('string'),
    description: attr('string'),
    category: attr('string'),
    shortDescription: attr('string'),
    date: attr('number'),
    isPushEnabled: attr('boolean'),
    notificationText: attr('string'),
    notificationTime: attr('number'),
    videoLink: attr('string')
  };
  _exports.schema = schema;

  var _default = _emberData.default.Model.extend(schema);

  _exports.default = _default;
});