define("client/main/not-found-redirect/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    router: Ember.inject.service(),

    redirect() {
      const url = this.router.location.formatURL('/not-found');

      if (window.location.pathname !== url) {
        this.transitionTo('/not-found');
      }
    }

  });

  _exports.default = _default;
});