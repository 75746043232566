define("client/main/error/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController() {
      this._super(...arguments);

      this.transitionTo('/not-found-redirect');
    }

  });

  _exports.default = _default;
});