define("client/main/components/i-validated-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GlarcWXI",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"label\"],[12,\"for\",[27,\"concat\",[[23,[\"type\"]],\"-\",[23,[\"property\"]]],null]],[11,\"class\",\"control-label\"],[9],[1,[21,\"labelText\"],false],[10],[0,\"\\n\"],[4,\"power-select\",null,[[\"triggerClass\",\"searchEnabled\",\"options\",\"disabled\",\"selected\",\"searchField\",\"searchPlaceholder\",\"onchange\"],[\"form-control\",[23,[\"searchEnabled\"]],[23,[\"options\"]],[27,\"eq\",[[23,[\"options\",\"length\"]],0],null],[23,[\"selected\"]],[23,[\"searchField\"]],[23,[\"searchPlaceholder\"]],[23,[\"onChange\"]]]],{\"statements\":[[0,\"  \"],[1,[27,\"get\",[[23,[\"optionNames\"]],[22,1,[\"id\"]]],null],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"isInvalid\"]]],null,{\"statements\":[[0,\"  \"],[7,\"small\"],[11,\"class\",\"form-control-feedback\"],[9],[0,\" \"],[1,[21,\"isInvalid\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/main/components/i-validated-select/template.hbs"
    }
  });

  _exports.default = _default;
});