define("client/main/components/forms/news-form/component", ["exports", "ember-changeset", "ember-changeset-validations", "moment", "client/main/components/forms/news-form/validations"], function (_exports, _emberChangeset, _emberChangesetValidations, _moment, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'form',
    isAddPhotoShowing: true,
    classNames: [''],
    intl: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),
    session: Ember.inject.service(),

    init(...args) {
      this._super(...args);

      this.categories = ["environment", "airlines", "brainport_eindhoven", "airforce", "partners_and_events", "other"];
    },

    isEditor: Ember.computed('session.currentUser.type', {
      get() {
        const role = this.get('session.currentUser.type');
        return role === 'editor';
      }

    }),

    async submit(e) {
      e.preventDefault();
      const changeset = this.get('changeset');
      const snapshot = changeset.snapshot();

      try {
        await changeset.validate();

        if (Ember.get(changeset, 'isInvalid')) {
          this.get('notifications').error(this.intl.t('error-messages.form'));
        } else {
          Ember.set(changeset, 'imageUrl', changeset.get('images').get('firstObject') || null);
          this.onSubmit(changeset);
        }
      } catch (error) {
        console.error(error);
        changeset.restore(snapshot);
        this.get('notifications').error(this.intl.t('error-messages.default'));
      }
    },

    changeset: Ember.computed('model', {
      get() {
        const model = Ember.get(this, 'model');
        const changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_validations.default), _validations.default);
        return changeset;
      }

    }),
    formattedAvailability: Ember.computed('changeset.date', {
      get() {
        if (!this.get('changeset.date')) {
          return '';
        }

        return (0, _moment.default)(this.get('changeset.date')).format('HH:mm DD / MMMM YYYY');
      }

    }),
    formattedNotificationTime: Ember.computed('changeset.notificationTime', {
      get() {
        if (!this.get('changeset.notificationTime')) {
          return '';
        }

        return (0, _moment.default)(this.get('changeset.notificationTime')).format('HH:mm DD / MMMM YYYY');
      }

    }),
    actions: {
      updateCategory(category) {
        this.get("changeset").set("category", category);
      },

      updateAvailabilityDate(inputEvent, date) {
        this.get('changeset').set('date', date.valueOf());
      },

      updateNotificationDate(inputEvent, date) {
        this.get('changeset').set('notificationTime', date.valueOf());
      },

      addImage(index, url) {
        const images = this.get('changeset.images');
        images[index] = url;
        this.get('changeset').set('images', images);
      },

      addNewImage(url) {
        const images = this.get('changeset.images');
        images.pushObject(url);
        this.get('changeset').set('images', images);
        this.toggleProperty('isAddPhotoShowing');
        setTimeout(() => {
          this.toggleProperty('isAddPhotoShowing');
        }, 100);
      },

      removeImage(url) {
        const images = this.get('changeset.images');
        images.removeObject(url);
        this.get('changeset').set('images', images);
      }

    }
  });

  _exports.default = _default;
});