define("client/main/noise-feedback/controller", ["exports", "moment", "client/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    weekFilter: null,
    yearFilter: (0, _moment.default)().year(),
    dailyFeedbackFilter: (0, _moment.default)().valueOf(),
    zipCodeWeekFilter: null,
    apiURL: _environment.default.apiUrl,
    years: Ember.computed({
      get() {
        const currentYear = (0, _moment.default)().year();
        return [currentYear, currentYear - 1, currentYear - 2, currentYear - 3, currentYear - 4];
      }

    }),
    weeks: Ember.computed({
      get() {
        let result = [];

        for (let i = 0; i < 52; i++) {
          result.push({
            name: `Week ${i + 1}`,
            value: i + 1
          });
        }

        return result;
      }

    }),
    weekZipCodes: Ember.computed("model.dailyNoiseFeedback", {
      get() {
        return Object.keys(this.get("model.dailyNoiseFeedback") || {});
      }

    }),
    weekFeedbackData: Ember.computed("model.dailyNoiseFeedback", "yearFilter", "weekFilter", "zipCodeWeekFilter", {
      get() {
        const allFeedback = this.get("model.dailyNoiseFeedback") || {};
        let filteredData = Object.keys(allFeedback).reduce((all, nextZip) => {
          const feedbackForZip = allFeedback[nextZip];
          return all.concat(Object.keys(feedbackForZip).map(key => feedbackForZip[key]));
        }, []);

        if (this.get("yearFilter")) {
          filteredData = filteredData.filter(item => (0, _moment.default)(item.date).year() === this.get("yearFilter"));
        }

        if (this.get("zipCodeWeekFilter")) {
          const feedbackForZip = Ember.get(allFeedback, this.get("zipCodeWeekFilter"));
          filteredData = Object.keys(feedbackForZip).map(key => feedbackForZip[key]);
        }

        if (this.get("weekFilter")) {
          filteredData = filteredData.filter(item => (0, _moment.default)(item.date).week() === this.get("weekFilter.value"));
        }

        return filteredData;
      }

    }),
    averageWeekRating: Ember.computed("weekFeedbackData.[]", {
      get() {
        const calculated = this.get("weekFeedbackData").reduce((sum, next) => sum += next.rate, 0) / this.get("weekFeedbackData").length;

        if (isNaN(calculated)) {
          return 0;
        } else {
          return (calculated + 1).toFixed(2);
        }
      }

    }),
    weekChartData: Ember.computed("weekFeedbackData.[]", {
      get() {
        const allData = this.get("weekFeedbackData");
        const result = [{
          label: "5",
          value: allData.filter(i => i.rate === 4).length,
          color: "#229A48"
        }, {
          label: "4",
          value: allData.filter(i => i.rate === 3).length,
          color: "#A0BD47"
        }, {
          label: "3",
          value: allData.filter(i => i.rate === 2).length,
          color: "#F4BA32"
        }, {
          label: "2",
          value: allData.filter(i => i.rate === 1).length,
          color: "#E1832E"
        }, {
          label: "1",
          value: allData.filter(i => i.rate === 0).length,
          color: "#D22D3D"
        }];
        return result;
      }

    }),
    dailyFeedbackData: Ember.computed("model.dailyNoiseFeedback", "dailyFeedbackFilter", "zipCodeDailyFilter", {
      get() {
        const allFeedback = this.get("model.dailyNoiseFeedback") || {};
        let filteredData = Object.keys(allFeedback).reduce((all, nextZip) => {
          const feedbackForZip = allFeedback[nextZip];
          return all.concat(Object.keys(feedbackForZip).map(key => feedbackForZip[key]));
        }, []);

        if (this.get("zipCodeDailyFilter")) {
          const feedbackForZip = Ember.get(allFeedback, this.get("zipCodeDailyFilter"));
          filteredData = Object.keys(feedbackForZip).map(key => feedbackForZip[key]);
        }

        if (this.get("dailyFeedbackFilter")) {
          filteredData = filteredData.filter(item => (0, _moment.default)(item.date).isBetween((0, _moment.default)(this.get("dailyFeedbackFilter")).startOf("d"), (0, _moment.default)(this.get("dailyFeedbackFilter")).endOf("d")));
        }

        return filteredData;
      }

    }),
    averageDailyRating: Ember.computed("dailyFeedbackData.[]", {
      get() {
        const calculated = this.get("dailyFeedbackData").reduce((sum, next) => sum += next.rate, 0) / this.get("dailyFeedbackData").length;

        if (isNaN(calculated)) {
          return 0;
        } else {
          return (calculated + 1).toFixed(2);
        }
      }

    }),
    dailyChartData: Ember.computed("dailyFeedbackData.[]", {
      get() {
        const allData = this.get("dailyFeedbackData");
        const result = [{
          label: "5",
          value: allData.filter(i => i.rate === 4).length,
          color: "#229A48"
        }, {
          label: "4",
          value: allData.filter(i => i.rate === 3).length,
          color: "#A0BD47"
        }, {
          label: "3",
          value: allData.filter(i => i.rate === 2).length,
          color: "#F4BA32"
        }, {
          label: "2",
          value: allData.filter(i => i.rate === 1).length,
          color: "#E1832E"
        }, {
          label: "1",
          value: allData.filter(i => i.rate === 0).length,
          color: "#D22D3D"
        }];
        return result;
      }

    }),
    hourlyChartData: Ember.computed("dailyFeedbackData.[]", {
      get() {
        const allData = this.get("dailyFeedbackData");
        const sorted = {};
        allData.forEach(item => {
          const hour = (0, _moment.default)(item.date).format("HH");

          if (!sorted[`${hour}:00`]) {
            sorted[`${hour}:00`] = [];
          }

          sorted[`${hour}:00`].push(item);
        });
        let result = [];
        Object.keys(sorted).forEach(hour => {
          result.push({
            value: sorted[hour].filter(i => i.rate === 0).length,
            group: hour,
            label: "Rated 1",
            color: "#D22D3D"
          });
          result.push({
            value: sorted[hour].filter(i => i.rate === 1).length,
            group: hour,
            label: "Rated 2",
            color: "#E1832E"
          });
          result.push({
            value: sorted[hour].filter(i => i.rate === 2).length,
            group: hour,
            label: "Rated 3",
            color: "#F4BA32"
          });
          result.push({
            value: sorted[hour].filter(i => i.rate === 3).length,
            group: hour,
            label: "Rated 4",
            color: "#A0BD47"
          });
          result.push({
            value: sorted[hour].filter(i => i.rate === 4).length,
            group: hour,
            label: "Rated 5",
            color: "#229A48"
          });
        });
        return result;
      }

    }),
    formattedDailyFeedbackFilter: Ember.computed("dailyFeedbackFilter", {
      get() {
        if (!this.get("dailyFeedbackFilter")) {
          return "";
        }

        return (0, _moment.default)(this.get("dailyFeedbackFilter")).format("DD / MMMM YYYY");
      }

    }),
    actions: {
      setDailyFeedbackFilter(inputEvent, date) {
        this.set("dailyFeedbackFilter", date.valueOf());
      },

      setExportRange(start, end) {
        if (!start || !end) {
          this.set("exportDateRange", null);
          this.set("exportDateRangeStart", null);
          this.set("exportDateRangeEnd", null);
        } else {
          this.set("exportDateRangeStart", start.valueOf());
          this.set("exportDateRangeEnd", end.valueOf());
          this.set("exportDateRange", `${start.format("DD / MMMM YYYY")} - ${end.format("DD / MMMM YYYY")}`);
        }
      }

    }
  });

  _exports.default = _default;
});