define("client/main/news/index/controller", ["exports", "moment", "client/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    page: 1,
    limit: 10,
    filter: '',
    config: _environment.default,
    session: Ember.inject.service(),
    isEditor: Ember.computed('session.currentUser.type', {
      get() {
        const role = this.get('session.currentUser.type');
        return role === 'editor';
      }

    }),
    noFilter: Ember.computed('filter', {
      get() {
        return Ember.isBlank(this.get('filter'));
      }

    }),
    filterYear: null,
    filterMonth: null,
    years: Ember.computed({
      get() {
        const currentYear = (0, _moment.default)().year();
        return [currentYear, currentYear - 1, currentYear - 2, currentYear - 3, currentYear - 4];
      }

    }),
    months: Ember.computed({
      get() {
        return [this.intl.t('labels.months.short.jan'), this.intl.t('labels.months.short.feb'), this.intl.t('labels.months.short.mar'), this.intl.t('labels.months.short.apr'), this.intl.t('labels.months.short.may'), this.intl.t('labels.months.short.jun'), this.intl.t('labels.months.short.july'), this.intl.t('labels.months.short.aug'), this.intl.t('labels.months.short.sep'), this.intl.t('labels.months.short.oct'), this.intl.t('labels.months.short.nov'), this.intl.t('labels.months.short.dec')];
      }

    }),
    paginatedItems: Ember.computed('model.articles.@each.{date,title}', 'model.feedback', 'filter', 'filterYear', 'filterMonth', {
      get() {
        const allFeedback = this.get('model.feedback') || {};
        return this.get('model.articles').map(item => {
          const feedback = allFeedback[item.id] || {};
          return {
            id: item.get('id'),
            title: item.get('title'),
            date: item.get('date'),
            scheduled: (0, _moment.default)(item.get('date')).isValid() ? (0, _moment.default)(item.get('date')).format('DD/MM/YYYY') : 'n/a',
            feedback5: Object.keys(feedback).filter(key => feedback[key] === 4).length,
            feedback4: Object.keys(feedback).filter(key => feedback[key] === 3).length,
            feedback3: Object.keys(feedback).filter(key => feedback[key] === 2).length,
            feedback2: Object.keys(feedback).filter(key => feedback[key] === 1).length,
            feedback1: Object.keys(feedback).filter(key => feedback[key] === 0).length,
            feedbackTotal: Object.keys(feedback).length,
            checked: false
          };
        }).filter(item => {
          if (this.get('filterYear')) {
            let yearMatched = +(0, _moment.default)(item.scheduled, 'DD/MM/YYYY').format('YYYY') === +this.get('filterYear');

            if (this.get('filterMonth')) {
              return yearMatched && (0, _moment.default)(item.scheduled, 'DD/MM/YYYY').month() === this.get('months').indexOf(this.get('filterMonth'));
            } else {
              return yearMatched;
            }
          }

          return true;
        }).filter(item => {
          const itemHash = `${item.title}`;
          return this.get('noFilter') || itemHash.toUpperCase().includes(this.get('filter').toUpperCase());
        });
      }

    }),
    draftArticles: Ember.computed('paginatedItems.@each.date', {
      get() {
        return this.get('paginatedItems').filter(i => !i.date);
      }

    }),
    publishingArticles: Ember.computed('paginatedItems.@each.date', {
      get() {
        return this.get('paginatedItems').filter(i => i.date);
      }

    }),
    selectedItems: Ember.computed('paginatedItems.@each.checked', {
      get() {
        return this.get('paginatedItems').filter(i => i.checked).map(i => this.get('model.articles').findBy('id', i.id));
      }

    }),

    init() {
      this._super(...arguments);
    },

    actions: {
      selectAll(array) {
        const selectedCount = this.get('selectedItems.length');
        const allCount = array.length;

        if (selectedCount === allCount) {
          array.forEach(item => Ember.set(item, 'checked', false));
        } else {
          array.forEach(item => Ember.set(item, 'checked', true));
        }
      },

      async deleteSelected(selectedItems) {
        const confirmed = await swal({
          title: this.intl.t('confirmation.question'),
          text: this.intl.t('confirmation.delete-linked'),
          dangerMode: true,
          buttons: {
            cancel: {
              text: this.intl.t('actions.cancel'),
              value: null,
              visible: true,
              className: '',
              closeModal: true
            },
            confirm: {
              text: this.intl.t('actions.ok'),
              value: true,
              visible: true,
              className: '',
              closeModal: false
            }
          }
        }).then(async confirmed => {
          if (confirmed) {
            try {
              await Promise.all(this.get('model.articles').filter(i => selectedItems.map(selected => selected.id).includes(i.id)).map(i => i.destroyRecord()));
              return true;
            } catch (error) {
              swal(this.intl.t('error-messages.error'), this.intl.t('error-messages.request'), 'error');
            }
          }
        });

        if (confirmed) {
          swal(this.intl.t('confirmation.deleted'), this.intl.t('categories-page.deleted'), 'success');
        }
      }

    }
  });

  _exports.default = _default;
});