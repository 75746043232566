define("client/main/not-found/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "86hAvQ5N",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"error-box\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"error-body text-center\"],[9],[0,\"\\n    \"],[7,\"h1\"],[11,\"class\",\"text-info\"],[9],[0,\"404\"],[10],[0,\"\\n    \"],[7,\"h3\"],[11,\"class\",\"text-uppercase\"],[9],[0,\"Page Not Found !\"],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"text-muted m-t-30 m-b-30\"],[9],[0,\"PAGE YOU ARE TRYING TO FIND DOES NOT EXIST\"],[10],[0,\"\\n    \"],[4,\"link-to\",[\"dashboard\"],[[\"class\"],[\"btn btn-info btn-rounded waves-effect waves-light m-b-40\"]],{\"statements\":[[0,\"Back to home\"]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/main/not-found/template.hbs"
    }
  });

  _exports.default = _default;
});