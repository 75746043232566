define("client/main/configs/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    firebase: Ember.inject.service(),
    isProcessing: false,
    actions: {
      goBack() {
        this.transitionToRoute('dashboard');
      },

      async save(changeset) {
        try {
          const ref = this.get('firebase');
          this.set('isProcessing', true);
          const snapshot = changeset.snapshot();
          await ref.child('/configs').update(snapshot.changes);
          this.set('isProcessing', false);
          this.get('notifications').success(this.intl.t('configs-page.saved'));
        } catch (error) {
          console.error(error);
          this.set('isProcessing', false);
          this.get('notifications').error(this.intl.t('error-messages.default'));
        }
      }

    }
  });

  _exports.default = _default;
});