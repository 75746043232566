define("client/main/dashboard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TAB2bIpo",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n\\n\\n\\n\\n\"],[1,[21,\"inject-theme-customization\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/main/dashboard/template.hbs"
    }
  });

  _exports.default = _default;
});