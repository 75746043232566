define("client/main/components/layout/main-navbar/component", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'header',
    classNames: ['topbar'],
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    config: _environment.default,

    didInsertElement() {
      $('.fix-header .topbar').stick_in_parent({});
    },

    currentLocale: Ember.computed('intl.locale', {
      get() {
        return this.get('intl.locale')[0] || 'en-us';
      }

    }),
    actions: {
      logout() {
        this.session.invalidate();
      },

      setLocale(locale) {
        this.intl.setLocale(locale);
      }

    }
  });

  _exports.default = _default;
});