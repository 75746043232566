define("client/main/reset-password/controller", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    fb: Ember.inject.service('firebase-app'),
    intl: Ember.inject.service(),
    isProcessing: false,
    config: _environment.default,
    actions: {
      reset(e) {
        e.preventDefault();
        this.set('isProcessing', true);
        const auth = this.get('fb').auth();
        const email = this.get('email');

        if (Ember.isBlank(email)) {
          return this.resetFailed(this.intl.t('error-messages.reset-password'));
        }

        auth.sendPasswordResetEmail(email).then(this.resetSuccess.bind(this), this.resetFailed.bind(this));
      }

    },

    resetSuccess() {
      this.set('isProcessing', false);
      this.get('notifications').success(this.intl.t('confirmation.reset-password-success'));
      this.transitionToRoute('login');
    },

    resetFailed(err) {
      console.error(err);
      this.set('isProcessing', false);
      this.get('notifications').error(err);
    }

  });

  _exports.default = _default;
});