define("client/main/components/forms/category-form/component", ["exports", "ember-changeset", "ember-changeset-validations", "client/main/components/forms/category-form/validations"], function (_exports, _emberChangeset, _emberChangesetValidations, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'form',
    classNames: [''],
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),

    init() {
      this._super(...arguments);
    },

    async submit(e) {
      e.preventDefault();
      const changeset = this.get('changeset');
      const snapshot = changeset.snapshot();

      try {
        await changeset.validate();

        if (Ember.get(changeset, 'isInvalid')) {
          this.get('notifications').error(this.intl.t('error-messages.form'));
        } else {
          this.onSubmit(changeset);
        }
      } catch (error) {
        console.error(error);
        changeset.restore(snapshot);
        this.get('notifications').error(this.intl.t('error-messages.default'));
      }
    },

    changeset: Ember.computed('model', {
      get() {
        const model = Ember.get(this, 'model');
        const changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_validations.default), _validations.default);
        return changeset;
      }

    }),
    actions: {}
  });

  _exports.default = _default;
});