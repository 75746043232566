define("client/helpers/inject-script", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.injectScript = injectScript;
  _exports.default = void 0;

  function injectScript(params
  /*, hash*/
  ) {
    return Ember.String.htmlSafe(params[0]);
  }

  var _default = Ember.Helper.helper(injectScript);

  _exports.default = _default;
});