define("client/main/application/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "client/config/environment"], function (_exports, _applicationRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    intl: Ember.inject.service(),

    beforeModel() {
      return this.intl.setLocale(['en-us', 'nl-nl']);
      /* array optional */
    },

    actions: {
      error(error) {
        if (error) {
          console.error(error);
          return this.transitionTo('error');
        }
      }

    },

    sessionInvalidated() {
      this.transitionTo('loading');

      if (this.get('_isFastBoot')) {
        this.transitionTo(_environment.default.routerRootURL);
      } else {
        window.location.replace(_environment.default.routerRootURL);
      }
    }

  });

  _exports.default = _default;
});