define("client/main/surveys/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "client/mixins/management-only"], function (_exports, _authenticatedRouteMixin, _managementOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_managementOnly.default, _authenticatedRouteMixin.default, {});

  _exports.default = _default;
});