define("client/main/categories/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aS+wK9GK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row page-titles\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-5 col-8 align-self-center\"],[9],[0,\"\\n        \"],[7,\"h3\"],[11,\"class\",\"text-themecolor m-b-0 m-t-0\"],[9],[1,[27,\"t\",[\"categories-page.index\"],null],false],[10],[0,\"\\n        \"],[7,\"ol\"],[11,\"class\",\"breadcrumb\"],[9],[0,\"\\n            \"],[7,\"li\"],[11,\"class\",\"breadcrumb-item\"],[9],[4,\"link-to\",[\"categories\"],null,{\"statements\":[[1,[27,\"t\",[\"categories-page.title\"],null],false]],\"parameters\":[]},null],[10],[0,\"\\n            \"],[7,\"li\"],[11,\"class\",\"breadcrumb-item active\"],[9],[1,[27,\"t\",[\"labels.new\"],null],false],[0,\" \"],[1,[27,\"t\",[\"categories-page.model\"],null],false],[10],[0,\"\\n\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-lg-12\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n                \"],[1,[27,\"forms/category-form\",null,[[\"model\",\"onSubmit\",\"onCancel\"],[[23,[\"model\",\"category\"]],[27,\"action\",[[22,0,[]],\"save\"],null],[27,\"action\",[[22,0,[]],\"goBack\"],null]]]],false],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"processing-indicator\",null,[[\"show\"],[[23,[\"isProcessing\"]]]]],false],[0,\"\\n\"],[1,[21,\"inject-theme-customization\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/main/categories/new/template.hbs"
    }
  });

  _exports.default = _default;
});