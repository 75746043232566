define("client/main/categories/new/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { computed, get } from '@ember/object';
  var _default = Ember.Controller.extend({
    isProcessing: false,
    actions: {
      goBack() {
        this.transitionToRoute('categories');
      },

      async save(changeset) {
        try {
          this.set('isProcessing', true);
          await changeset.save();
          this.set('isProcessing', false);
          this.get('notifications').success(this.intl.t('categories-page.created'));
          this.transitionToRoute('categories');
        } catch (error) {
          this.set('isProcessing', false);
          console.error(error);
          this.get('notifications').error(this.intl.t('error-messages.default'));
        }
      }

    }
  });

  _exports.default = _default;
});