define("client/main/components/forms/category-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZI7ZEtMe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"form-body\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row p-t-20\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-3\"],[9],[0,\"\\n            \"],[1,[27,\"file-upload\",null,[[\"locationPrefix\",\"value\",\"onUpload\",\"onRemove\"],[\"articles\",[23,[\"changeset\",\"iconUrl\"]],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"changeset\",\"iconUrl\"]]],null]],null],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"changeset\",\"iconUrl\"]]],null]],null]]]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-md-9\"],[9],[0,\"\\n\\n            \"],[1,[27,\"i-validated-input\",null,[[\"type\",\"changeset\",\"labelText\",\"property\"],[\"text\",[23,[\"changeset\"]],[27,\"t\",[\"models.category.name\"],null],\"name\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"hr\"],[9],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"form-actions\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-success\"],[11,\"type\",\"submit\"],[9],[0,\" \"],[7,\"i\"],[11,\"class\",\"fa fa-check\"],[9],[10],[0,\" \"],[1,[27,\"t\",[\"actions.save\"],null],false],[10],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-inverse\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],[23,[\"onCancel\"]]],null]],[11,\"type\",\"button\"],[9],[1,[27,\"t\",[\"actions.cancel\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/main/components/forms/category-form/template.hbs"
    }
  });

  _exports.default = _default;
});