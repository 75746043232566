define("client/main/components/forms/config-form/validations", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {// mapInfo: [validatePresence({ presence: true, message: "{description} can't be blank" })],
    // aboutAirport: [validatePresence({ presence: true, message: "{description} can't be blank" })],
    // openingHours: [validatePresence({ presence: true, message: "{description} can't be blank" })],
    // accessibility: [validatePresence({ presence: true, message: "{description} can't be blank" })],
    // aboutBurenApp: [validatePresence({ presence: true, message: "{description} can't be blank" })]
  };
  _exports.default = _default;
});