define("client/components/paper-toast-text", ["exports", "ember-paper/components/paper-toast-text"], function (_exports, _paperToastText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _paperToastText.default;
    }
  });
});