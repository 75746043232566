define("client/mixins/admin-only", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      const role = this.get('session.currentUser.type');

      if (role !== 'admin') {
        this.transitionTo('not-found-redirect');
      }
    }

  });

  _exports.default = _default;
});