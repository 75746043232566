define("client/main/articles/edit/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model(params) {
      return Ember.RSVP.hash({
        article: this.store.findRecord('article', params.id),
        categories: this.store.findAll('category', {
          reload: true
        })
      });
    }

  });

  _exports.default = _default;
});