define("client/main/components/forms/user-form/component", ["exports", "ember-changeset", "ember-changeset-validations", "client/main/components/forms/user-form/validations", "client/main/components/forms/user-form/validations-new"], function (_exports, _emberChangeset, _emberChangesetValidations, _validations, _validationsNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'form',
    classNames: [''],
    isInviteForm: false,
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),
    withAdminRole: Ember.computed('session.currentUser.type', {
      get() {
        return this.get('session.currentUser.type') === 'admin';
      }

    }),

    init() {
      this._super(...arguments);

      this.typeNames = {
        admin: 'Administrator',
        editor: 'Content Editor',
        manager: 'Content Manager',
        'users-manager': 'Users Manager',
        'app-user': 'Application User'
      };
      this.types = ['admin', 'manager', 'editor', 'users-manager'];
    },

    async submit(e) {
      e.preventDefault();
      const changeset = this.get('changeset');
      const snapshot = changeset.snapshot();

      try {
        await changeset.validate();

        if (Ember.get(changeset, 'isInvalid')) {
          this.get('notifications').error(this.intl.t('error-messages.form'));
        } else {
          this.onSubmit(changeset);
        }
      } catch (error) {
        console.error(error);
        changeset.restore(snapshot);
        this.get('notifications').error(this.intl.t('error-messages.default'));
      }
    },

    changeset: Ember.computed('model', 'isInviteForm', {
      get() {
        const UserInvite = Ember.Object.extend();
        const userInvite = UserInvite.create({
          type: 'app-user',
          company: this.get('companies.firstObject')
        });
        const model = this.get('isInviteForm') ? userInvite : Ember.get(this, 'model');
        const validations = model.get('isNew') ? _validationsNew.default : _validations.default;
        const changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(validations), validations);
        return changeset;
      }

    }),
    actions: {
      setType(type) {
        const changeset = this.get('changeset');

        if (type !== 'app-user') {
          changeset.set('company', null);
        } else {
          changeset.set('company', this.get('companies.firstObject'));
        }

        changeset.set('type', type);
      },

      placeSelected(changeset, locationObject) {
        if (Ember.isBlank(locationObject.name)) {
          this.set('isInvalidLocation', false);
          Ember.set(changeset, 'lat', null);
          Ember.set(changeset, 'lng', null);
          Ember.set(changeset, 'address', null);
          return;
        }

        if (!locationObject.geometry) {
          this.set('isInvalidLocation', true);
          return;
        }

        Ember.set(changeset, 'lat', locationObject.geometry.location.lat());
        Ember.set(changeset, 'lng', locationObject.geometry.location.lng());
        Ember.set(changeset, 'address', locationObject.formatted_address);
        this.set('isInvalidLocation', false);
      },

      checkAddressClear(changeset) {
        if (Ember.isBlank(changeset.get('address'))) {
          Ember.set(changeset, 'lat', null);
          Ember.set(changeset, 'lng', null);
        }
      }

    }
  });

  _exports.default = _default;
});