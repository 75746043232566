define("client/main/surveys/new/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "moment"], function (_exports, _authenticatedRouteMixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    async model() {
      return Ember.RSVP.hash({
        survey: this.store.createRecord('survey', {
          startDate: (0, _moment.default)().valueOf(),
          active: true
        })
      });
    },

    actions: {
      willTransition() {
        if (this.controller.get('model.survey')) {
          this.controller.get('model.survey').unloadRecord();
        }
      }

    }
  });

  _exports.default = _default;
});