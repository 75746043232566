define("client/main/surveys/new/controller", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isProcessing: false,
    ajax: Ember.inject.service(),
    actions: {
      goBack() {
        this.transitionToRoute('surveys');
      },

      async save(changeset) {
        try {
          this.set('isProcessing', true);
          const saved = await changeset.save();
          this.set('isProcessing', false);
          this.get('notifications').success(this.intl.t('surveys-page.created'));
          this.transitionToRoute('surveys');
        } catch (error) {
          this.set('isProcessing', false);
          console.error(error);
          this.get('notifications').error(this.intl.t('error-messages.default'));
        }
      }

    }
  });

  _exports.default = _default;
});