define("client/main/articles/new/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    async model(params) {
      const categories = await this.store.findAll('category', {
        reload: true
      });
      const category = params.category ? categories.findBy('id', params.category) : categories.get('firstObject');
      return Ember.RSVP.hash({
        article: this.store.createRecord('article', {
          category,
          images: Ember.A()
        }),
        categories
      });
    },

    actions: {
      willTransition() {
        if (this.controller.get('model.article')) {
          this.controller.get('model.article').unloadRecord();
        }
      }

    }
  });

  _exports.default = _default;
});