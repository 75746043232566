define("client/main/components/summernote-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['c-summernote-editor'],
    classNameBindings: ['isFocused:has-focus'],

    didInsertElement() {
      const _this = this;

      this.$('.summernote').summernote({
        toolbar: [// [groupName, [list of button]]
        ['style', ['bold', 'italic', 'underline', 'clear']], ['font', ['strikethrough', 'superscript', 'subscript']], ['fontsize', ['fontsize']], ['color', ['color']], ['para', ['ul', 'ol', 'paragraph']], ['height', ['height']], ['insert', ['hr', 'link']]],
        height: 350,
        // set editor height
        minHeight: null,
        // set minimum height of editor
        maxHeight: null,
        // set maximum height of editor
        focus: false,
        // set focus to editable area after initializing summernote
        callbacks: {
          onChange: function (contents) {
            _this.onChange(contents);
          },
          onFocus: function () {
            _this.set('isFocused', true);
          },
          onBlur: function () {
            _this.set('isFocused', false);
          }
        }
      });
    }

  });

  _exports.default = _default;
});