define("client/main/users/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model() {
      return Ember.RSVP.hash({
        users: this.store.findAll('user', {
          reload: true
        }).then(users => users.filter(u => !u.get('isDeleted') && ['admin', 'editor', 'manager', 'users-manager'].includes(u.get('type'))))
      });
    }

  });

  _exports.default = _default;
});