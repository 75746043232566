define("client/torii-providers/firebase", ["exports", "emberfire/torii-providers/firebase"], function (_exports, _firebase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _firebase.default.extend({
    fetch(data) {
      return data;
    },

    open() {
      const result = this._super(...arguments);

      return result.then(resolved => {
        const formatted = {
          uid: resolved.uid,
          refreshToken: resolved.refreshToken,
          idToken: resolved.Yd,
          email: resolved.email,
          registered: true
        };
        return formatted;
      });
    }

  });

  _exports.default = _default;
});