define("client/main/components/inject-theme-customization/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      /*
      Template Name: Material Pro Admin
      Author: Themedesigner
      Email: niravjoshi87@gmail.com
      File: js
      */
      $(function () {
        'use strict';

        $(function () {
          $('.preloader').fadeOut();
        });
        jQuery(document).on('click', '.mega-dropdown', function (e) {
          e.stopPropagation();
        }); // ==============================================================
        // Right sidebar options
        // ==============================================================

        $('.right-side-toggle').click(function () {
          $('.right-sidebar').slideDown(50);
          $('.right-sidebar').toggleClass('shw-rside');
        });
        $('.floating-labels .form-control').on('focus blur', function (e) {
          $(this).parents('.form-group').toggleClass('focused', e.type === 'focus' || this.value.length > 0);
        }).trigger('blur'); // ==============================================================
        // Auto select left navbar
        // ==============================================================

        $(function () {
          var url = window.location;
          var element = $('ul#sidebarnav a').filter(function () {
            return this.href == url;
          }).addClass('active').parent().addClass('active');

          while (true) {
            if (element.is('li')) {
              element = element.parent().addClass('in').parent().addClass('active');
            } else {
              break;
            }
          }
        }); // ==============================================================
        //tooltip
        // ==============================================================

        $(function () {
          $('[data-toggle="tooltip"]').tooltip();
        }); // ==============================================================
        //Popover
        // ==============================================================

        $(function () {
          $('[data-toggle="popover"]').popover();
        }); // ==============================================================
        // Slimscrollbars
        // ==============================================================

        $('.scroll-sidebar').slimScroll({
          position: 'left',
          size: '5px',
          height: '100%',
          color: '#dcdcdc'
        });
        $('.message-center').slimScroll({
          position: 'right',
          size: '5px',
          color: '#dcdcdc'
        });
        $('.aboutscroll').slimScroll({
          position: 'right',
          size: '5px',
          height: '80',
          color: '#dcdcdc'
        });
        $('.message-scroll').slimScroll({
          position: 'right',
          size: '5px',
          height: '570',
          color: '#dcdcdc'
        });
        $('.chat-box').slimScroll({
          position: 'right',
          size: '5px',
          height: '470',
          color: '#dcdcdc'
        });
        $('.slimscrollright').slimScroll({
          height: '100%',
          position: 'right',
          size: '5px',
          color: '#dcdcdc'
        }); // ==============================================================
        // Resize all elements
        // ==============================================================

        $('body').trigger('resize'); // ==============================================================
        // To do list
        // ==============================================================

        $('.list-task li label').click(function () {
          $(this).toggleClass('task-done');
        }); // ==============================================================
        // Login and Recover Password
        // ==============================================================

        $('#to-recover').on('click', function () {
          $('#loginform').slideUp();
          $('#recoverform').fadeIn();
        }); // ==============================================================
        // Collapsable cards
        // ==============================================================

        $('a[data-action="collapse"]').on('click', function (e) {
          e.preventDefault();
          $(this).closest('.card').find('[data-action="collapse"] i').toggleClass('ti-minus ti-plus');
          $(this).closest('.card').children('.card-body').collapse('toggle');
        }); // Toggle fullscreen

        $('a[data-action="expand"]').on('click', function (e) {
          e.preventDefault();
          $(this).closest('.card').find('[data-action="expand"] i').toggleClass('mdi-arrow-expand mdi-arrow-compress');
          $(this).closest('.card').toggleClass('card-fullscreen');
        }); // Close Card

        $('a[data-action="close"]').on('click', function () {
          $(this).closest('.card').removeClass().slideUp('fast');
        });
      });
    }

  });

  _exports.default = _default;
});