define("client/main/news-feedback/controller", ["exports", "moment", "client/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    articleFilter: null,
    apiURL: _environment.default.apiUrl,
    feedbackData: Ember.computed('model.newsFeedback', 'articleFilter', {
      get() {
        const allFeedback = this.get('model.newsFeedback') || {};
        let filteredData = Object.keys(allFeedback).reduce((all, nextArticleKey) => {
          const feedbackForArticle = allFeedback[nextArticleKey];
          return all.concat(Object.keys(feedbackForArticle).map(key => feedbackForArticle[key]));
        }, []);

        if (this.get('articleFilter.id')) {
          const feedbackForArticle = Ember.get(allFeedback, this.get('articleFilter.id'));
          filteredData = Object.keys(feedbackForArticle).map(key => feedbackForArticle[key]);
        }

        return filteredData;
      }

    }),
    averageRating: Ember.computed('feedbackData.[]', {
      get() {
        const calculated = this.get('feedbackData').reduce((sum, next) => sum += next, 0) / this.get('feedbackData').length;
        return (calculated + 1).toFixed(2) || 0;
      }

    }),
    chartData: Ember.computed('feedbackData.[]', {
      get() {
        const allData = this.get('feedbackData');
        const result = [{
          label: '5',
          value: allData.filter(i => i === 4).length,
          color: '#229A48'
        }, {
          label: '4',
          value: allData.filter(i => i === 3).length,
          color: '#A0BD47'
        }, {
          label: '3',
          value: allData.filter(i => i === 2).length,
          color: '#F4BA32'
        }, {
          label: '2',
          value: allData.filter(i => i === 1).length,
          color: '#E1832E'
        }, {
          label: '1',
          value: allData.filter(i => i === 0).length,
          color: '#D22D3D'
        }];
        return result;
      }

    })
  });

  _exports.default = _default;
});