define("client/translations/nl-nl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "actions": {
      "add": "Toevoegen",
      "cancel": "Annuleren",
      "delete": "Verwijderen",
      "edit": "Bewerken",
      "export": "Export",
      "ok": "OK",
      "save": "Opslaan",
      "saveDraft": "Opslaan als concept",
      "send": "Verzenden",
      "update": "Update"
    },
    "articles-page": {
      "add": "Artikel toevoegen",
      "categories": "Categorieën",
      "created": "Artikel toegevoegd",
      "deleted": "Artikel verwijderd",
      "form-title": "Artikel Info",
      "index": "Inhoudsopgave",
      "list": "Artikellijst",
      "model": "Artikel",
      "models": "Artikelen",
      "saved": "Artikel opgeslagen",
      "title": "Artikelen"
    },
    "categories-page": {
      "add": "Artikel toevoegen",
      "add-article": "Add Category Article",
      "created": "Artikel toegevoegd",
      "deleted": "Artikel verwijderd",
      "index": "Inhoudsopgave",
      "model": "Categorie",
      "parent": "hoofdcategorie",
      "products": "Producten",
      "saved": "Artikel opgeslagen",
      "subcategories": "Subcategorieën",
      "title": "Categorieën"
    },
    "configs-page": {
      "form-title": "Application settings",
      "saved": "Configuration opgeslagen.",
      "title": "Configuration"
    },
    "confirmation": {
      "delete-linked": "Met deze bewerking worden geselecteerde items en alle gekoppelde onderliggende items verwijderd.",
      "deleted": "Verwijderd!",
      "invited": "Uitnodiging verzonden!",
      "message": "Deze bewerking kan niet ongedaan worden gemaakt.",
      "question": "Weet je het zeker?",
      "reset-password-success": "Password reset email sent!"
    },
    "error-messages": {
      "default": "Er is iets fout gegaan, probeer het nogmaals alstublieft.",
      "error": "Error!",
      "form": "Corrigeer de invoerfouten alstublieft.",
      "image-upload": "Er is een fout opgetreden bij het uploaden van de afbeelding, probeer het alstublieft opnieuw.",
      "request": "Het verzoek is mislukt",
      "reset-password": "Er is een fout opgetreden bij het herstellen van uw wachtwoord.",
      "reset-password-success": "Wachtwoord reset e-mail is verzonden.",
      "user-exists": "Gebruiker met deze e-mail is al toegevoegd."
    },
    "labels": {
      "all-months": "All months",
      "all-years": "All years",
      "disabled": "Uitschakelen",
      "enabled": "Inschakelen",
      "index": "Inhoudsopgave",
      "main": "Eindhoven Airport",
      "months": {
        "short": {
          "apr": "apr",
          "aug": "aug",
          "dec": "dec",
          "feb": "feb",
          "jan": "jan",
          "july": "jul",
          "jun": "jun",
          "mar": "mrt",
          "may": "mei",
          "nov": "nov",
          "oct": "okt",
          "sep": "sept"
        }
      },
      "new": "Nieuw",
      "no-items": "Er zijn nog geen items",
      "not-specified": "Niet gespecificeerd",
      "optional": "Optioneel"
    },
    "languages": {
      "dutch": "Nederlands",
      "english": "Engels"
    },
    "management-page": {
      "index": "Administrator",
      "title": "User Management"
    },
    "models": {
      "article": {
        "active": "Active",
        "category": "Categorie",
        "content": "Inhoud",
        "subtitle": "Sub-titel",
        "title": "Titel"
      },
      "category": {
        "name": "Naam"
      },
      "configs": {
        "aboutAirport": "About Eindhoven Airport",
        "aboutBurenApp": "Questions about the BurenApp",
        "accessibility": "Accessibility",
        "mapInfo": "Map Info",
        "openingHours": "Opening hours"
      },
      "news": {
        "active": "Active",
        "categories": {
          "airforce": "Vliegbasis",
          "airlines": "Luchtvaartmaatschappijen",
          "brainport_eindhoven": "Brainport Eindhoven",
          "environment": "Leefomgeving",
          "other": "Overig",
          "partners_and_events": "Partners & evenementen"
        },
        "content": "Inhoud",
        "notificationText": "Push Notification Text",
        "notificationTime": "Push Notification Date",
        "push": "Enable Push Notification",
        "shortDescription": "Short Description",
        "time": "Geplande tijd",
        "title": "Titel",
        "totalFeedback": "Total Feedback",
        "videoLink": "YouTube Video"
      },
      "survey": {
        "active": "Active",
        "end": "End Date",
        "event": "Event",
        "start": "Start Date",
        "title": "Surey titel"
      },
      "user": {
        "adultsInHousehold": "Adults in Household",
        "age": "Age Group",
        "bringsAndPickupsPerYear": "Airport Transfer",
        "childrenInHousehold": "Children in Household",
        "email": "Email",
        "flightsPerYear": "Flights per Year",
        "forms": {
          "default": "User Info",
          "invite": "Invite User"
        },
        "name": "Naam",
        "password": "Wachtwoord",
        "passwordConfirm": "Bevestig wachtwoord",
        "type": "Type",
        "zipCode": "ZIP Code"
      }
    },
    "news-feedback-page": {
      "article-filter": "All Articles",
      "average-ratings": "Average Rating",
      "overview": "Overview",
      "rates": "Rates",
      "title": "News",
      "total-ratings": "Total Ratings"
    },
    "news-page": {
      "drafts": "Conceptartikelen",
      "index": "News",
      "list": "Artikellijst",
      "model": "Artikel",
      "models": "Artikelen",
      "title": "Artikelen"
    },
    "noise-feedback-page": {
      "average-ratings": "Average Rating",
      "by-hour": "Ratings by Hour",
      "daily-feedback": "Daily Noise",
      "daily-filter": "All Dates",
      "export-week": "Export Week Data",
      "export-weekend": "Export Weekend Data",
      "rates": "Rates",
      "title": "Noise",
      "total-ratings": "Total Ratings",
      "week-feedback": "Week Noise",
      "week-filter": "All Weeks",
      "weekend-feedback": "Weekend Noise",
      "weekend-filter": "All Weekends",
      "zip-code-filter": "All ZIP Codes"
    },
    "placeholders": {
      "article-search": "Find your article (title)...",
      "category-search": "Zoeken naar categorie",
      "search": "Type om te zoeken",
      "survey-search": "Find your survey (title)..."
    },
    "sidebar": {
      "articles": "Artikelen",
      "categories": "Categories",
      "config": "Configuration",
      "environment": "Environment",
      "feedback": "Feedback",
      "news": "News",
      "noise": "Noise",
      "surveys": "Surveys",
      "user-management": "User Management",
      "users": "Users"
    },
    "surveys-page": {
      "add": "Add Survey",
      "created": "Survey added.",
      "deleted": "Survey removed.",
      "form-title": "Survey Info",
      "index": "Index",
      "list": "Survey List",
      "model": "Survey",
      "models": "Surveys",
      "saved": "Survey saved.",
      "title": "Surveys"
    },
    "users-page": {
      "add": "Gebruiker toevoegen",
      "created": "Gebruiker aangemaakt",
      "deleted": "Gebruiker is verwijderd",
      "index": "Feedback",
      "invite": "Nodig gebruiker uit",
      "invited": "Uitnodiging succesvol verzonden",
      "model": "Gebruiker",
      "new": "Nieuwe gebruiker",
      "saved": "Gebruiker aangepast",
      "title": "Gebruikers"
    }
  };
  _exports.default = _default;
});