define("client/main/noise-feedback/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    firebase: Ember.inject.service(),

    model() {
      const ref = this.get('firebase');
      const getDailyNoiseFeedback = new Ember.RSVP.Promise((resolve, reject) => {
        try {
          ref.child('/dailyNoiseFeedback').on('value', snapshot => resolve(snapshot.val()));
        } catch (error) {
          console.error(error);
          reject(error);
        }
      });
      return Ember.RSVP.hash({
        dailyNoiseFeedback: getDailyNoiseFeedback
      });
    }

  });

  _exports.default = _default;
});