define("client/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.schema = void 0;
  const {
    attr
  } = _emberData.default;
  const schema = {
    type: attr('string'),
    password: attr('string'),
    passwordConfirm: attr('string'),
    name: attr('string'),
    email: attr('string'),
    city: attr('string'),
    age: attr('number'),
    numberOfArticlesOpened: attr('number'),
    numberOfArticlesRated: attr('number'),
    weekendNoiseFeedbackDate: attr('number'),
    weekendNoiseFeedbackRate: attr('number'),
    weeklyNoiseFeedbackDate: attr('number'),
    weeklyNoiseFeedbackRate: attr('number'),
    zipCode: attr('string'),
    adultsInHousehold: attr('number'),
    bringsAndPickupsPerYear: attr('number'),
    childrenInHousehold: attr('number'),
    flightsPerYear: attr('number')
  };
  _exports.schema = schema;

  var _default = _emberData.default.Model.extend(schema);

  _exports.default = _default;
});