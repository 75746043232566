define("client/main/components/file-upload/component", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locationPrefix: '',
    uploadProgress: 0,
    classNames: ['c-file-upload'],
    notifications: Ember.inject.service('notification-messages'),
    intl: Ember.inject.service(),

    didInsertElement() {
      const dropifyEvents = this.$('.dropify').dropify(); // dropifyEvents.on('dropify.beforeClear', function(event, element) {
      //   return confirm('Do you really want to delete "' + element.file.name + '" ?');
      // });

      dropifyEvents.on('dropify.afterClear', () => {
        this.onRemove(null);
      });
      dropifyEvents.on('dropify.errors', () => {
        this.get('notifications').error(this.intl.t('error-messages.image-upload'));

        if (this.onError) {
          this.onError();
        }
      });
    },

    actions: {
      upload([photo]) {
        const fd = new FormData();
        fd.append('image', photo);
        fd.append('path', `${this.get('locationPrefix')}`);
        $.ajax({
          xhr: () => {
            var xhr = new window.XMLHttpRequest();
            this.set('uploadProgress', 0.1);
            this.set('isUploading', true);
            xhr.upload.addEventListener('progress', evt => {
              if (evt.lengthComputable) {
                var percentComplete = evt.loaded / evt.total;
                percentComplete = parseInt(percentComplete * 100);
                this.set('uploadProgress', percentComplete);
              }
            }, false);
            return xhr;
          },
          url: `${_environment.default.apiUrl}/api/utils/upload-photo`,
          type: 'POST',
          data: fd,
          processData: false,
          contentType: false,
          success: result => {
            this.set('isUploading', false);
            this.onUpload(result.path);
          },
          error: error => {
            console.error(error);
            this.set('isUploading', false);
            this.get('notifications').error(this.intl.t('error-messages.image-upload'));

            if (this.onError) {
              this.onError(error);
            }
          }
        });
      }

    }
  });

  _exports.default = _default;
});