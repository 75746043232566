define("client/main/users/new/controller", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    isProcessing: false,
    actions: {
      async save(changeset) {
        try {
          changeset.execute();
          const values = Ember.get(changeset, 'data');
          delete values.confirmPassword;
          this.set('isProcessing', true);
          await this.get('ajax').post(`${_environment.default.apiUrl}/api/users`, {
            data: values,
            contentType: 'application/json; charset=utf-8'
          });
          this.get('notifications').success(this.intl.t('users-page.created'));
          this.set('isProcessing', false);
          this.transitionToRoute('users');
        } catch (error) {
          console.error(error);
          this.set('isProcessing', false);
          this.get('notifications').error(this.intl.t('error-messages.default'));
        }
      },

      goBack() {
        this.transitionToRoute('users');
      }

    }
  });

  _exports.default = _default;
});