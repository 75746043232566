define("client/main/articles/new/controller", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isProcessing: false,
    category: '',
    queryParams: ['category'],
    ajax: Ember.inject.service(),
    actions: {
      goBack() {
        this.transitionToRoute('articles');
      },

      async save(changeset) {
        try {
          this.set('isProcessing', true);
          const saved = await changeset.save(); // if (saved.get('isActive') && saved.get('isPushEnabled')) {
          //   await this.get('ajax').post(`${ENV.apiUrl}/api/notifications`, {
          //     data: {
          //       topic: 'articles',
          //       notificationText: saved.get('notificationText'),
          //       title: saved.get('title'),
          //       key: saved.get('id')
          //     },
          //     contentType: 'application/json; charset=utf-8'
          //   });
          // }

          this.set('isProcessing', false);
          this.get('notifications').success(this.intl.t('articles-page.created'));
          this.transitionToRoute('articles');
        } catch (error) {
          this.set('isProcessing', false);
          console.error(error);
          this.get('notifications').error(this.intl.t('error-messages.default'));
        }
      }

    }
  });

  _exports.default = _default;
});