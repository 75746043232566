define("client/main/components/i-validated-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['component-i-validated-input', 'form-group'],
    classNameBindings: ['isInvalid:has-danger'],
    isDisabled: false,
    step: 1,
    isInvalid: Ember.computed('changeset.error', 'property', 'isPOJO', 'additionalValidation', {
      get() {
        if (this.additionalValidation) {
          const errors = this.additionalValidation(Ember.get(this.get('changeset'), this.get('property')));

          if (errors) {
            return errors;
          }
        }

        if (this.get('isPOJO')) {
          const [prop, field] = this.get('property').split('.');
          const errorObject = this.get('changeset.error')[prop];

          if (errorObject) {
            const [error] = errorObject.validation;
            return error[field];
          }
        } else {
          const errorObject = Ember.get(this.get('changeset.error'), this.get('property'));
          return errorObject && errorObject.validation[0];
        }
      }

    }),
    actions: {
      validateProperty(changeset, property) {
        return changeset.validate && changeset.validate(property);
      }

    }
  });

  _exports.default = _default;
});