define("client/main/news/new/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "moment"], function (_exports, _authenticatedRouteMixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    isEditor: Ember.computed('session.currentUser.type', {
      get() {
        const role = this.get('session.currentUser.type');
        return role === 'editor';
      }

    }),

    async model() {
      return Ember.RSVP.hash({
        article: this.store.createRecord('new', {
          images: Ember.A(),
          date: this.get('isEditor') ? null : (0, _moment.default)().valueOf()
        })
      });
    },

    actions: {
      willTransition() {
        if (this.controller.get('model.article')) {
          this.controller.get('model.article').unloadRecord();
        }
      }

    }
  });

  _exports.default = _default;
});