define("client/main/login/controller", ["exports", "client/config/environment", "lodash"], function (_exports, _environment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    isProcessing: false,
    config: _environment.default,
    actions: {
      signIn(e) {
        e.preventDefault();
        this.set('isProcessing', true);
        this.set('errorMessage', '');
        const email = this.get('email');
        const password = this.get('password');
        const opt = {
          provider: 'password',
          email: (0, _lodash.trim)(email),
          password
        };
        this.get('session').authenticate('authenticator:torii', 'firebase', opt).then(this.signInSuccess.bind(this)).catch(this.signInFailed.bind(this));
      }

    },

    signInSuccess() {
      this.set('isProcessing', false);
    },

    signInFailed(err) {
      console.error(err);
      this.get('notifications').error(err);
      this.set('isProcessing', false);
    }

  });

  _exports.default = _default;
});